var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"menu-nav"},[_c('router-link',{attrs:{"to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('div',{staticClass:"menu-icon-img"},[(!isActive && !isExactActive)?_c('img',{attrs:{"src":"/media/dashboard/menu-icons/home.png","alt":"Home"}}):_vm._e(),(isActive && isExactActive)?_c('img',{attrs:{"src":"/media/dashboard/menu-icons/home-red.png","alt":"Home"}}):_vm._e()]),_c('span',{staticClass:"menu-text"},[_vm._v("Home")])])])]}}])}),_c('router-link',{attrs:{"to":"/order-history-details"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('div',{staticClass:"menu-icon-img"},[(!isActive && !isExactActive)?_c('img',{attrs:{"src":"/media/dashboard/menu-icons/order-history.png","alt":"Order History"}}):_vm._e(),(isActive && isExactActive)?_c('img',{attrs:{"src":"/media/dashboard/menu-icons/order-history-red.png","alt":"Order History"}}):_vm._e()]),_c('span',{staticClass:"menu-text"},[_vm._v("Order History & Details")])])])]}}])}),_c('router-link',{attrs:{"to":"/business-lines"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('div',{staticClass:"menu-icon-img"},[(!isActive && !isExactActive)?_c('img',{attrs:{"src":"/media/dashboard/menu-icons/business-lines.png","alt":"Business Lines"}}):_vm._e(),(isActive && isExactActive)?_c('img',{attrs:{"src":"/media/dashboard/menu-icons/business-lines-red.png","alt":"Business Lines"}}):_vm._e()]),_c('span',{staticClass:"menu-text"},[_vm._v("Business Lines")])])])]}}])}),_c('router-link',{attrs:{"to":"/riders"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('div',{staticClass:"menu-icon-img"},[(!isActive && !isExactActive)?_c('img',{attrs:{"src":"/media/dashboard/menu-icons/baskytor-card.png","alt":"Baskytor"}}):_vm._e(),(isActive && isExactActive)?_c('img',{attrs:{"src":"/media/dashboard/menu-icons/baskytor-card-red.png","alt":"Rider"}}):_vm._e()]),_c('span',{staticClass:"menu-text"},[_vm._v("Baskytors")])])])]}}])}),_c('router-link',{attrs:{"to":"/vendor"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('div',{staticClass:"menu-icon-img"},[(!isActive && !isExactActive)?_c('img',{attrs:{"src":"/media/dashboard/menu-icons/vendor.png","alt":"Vendor"}}):_vm._e(),(isActive && isExactActive)?_c('img',{attrs:{"src":"/media/dashboard/menu-icons/vendor-red.png","alt":"Vendor"}}):_vm._e()]),_c('span',{staticClass:"menu-text"},[_vm._v("Vendor")])])])]}}])}),_c('router-link',{attrs:{"to":"/all-prodcuts"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('div',{staticClass:"menu-icon-img"},[(!isActive && !isExactActive)?_c('img',{attrs:{"src":"/media/dashboard/menu-icons/all-products.png","alt":"All Products"}}):_vm._e(),(isActive && isExactActive)?_c('img',{attrs:{"src":"/media/dashboard/menu-icons/all-products-red.png","alt":"All Products"}}):_vm._e()]),_c('span',{staticClass:"menu-text"},[_vm._v("All Products")])])])]}}])}),_c('router-link',{attrs:{"to":"/categories"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('div',{staticClass:"menu-icon-img"},[(!isActive && !isExactActive)?_c('img',{attrs:{"src":"/media/dashboard/menu-icons/cat-white.png","alt":"All Products"}}):_vm._e(),(isActive && isExactActive)?_c('img',{attrs:{"src":"/media/dashboard/menu-icons/cat-red.png","alt":"All Products"}}):_vm._e()]),_c('span',{staticClass:"menu-text"},[_vm._v("Categories")])])])]}}])}),_c('router-link',{attrs:{"to":"/users"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('div',{staticClass:"menu-icon-img"},[(!isActive && !isExactActive)?_c('img',{attrs:{"src":"/media/dashboard/menu-icons/users-white.png","alt":"All Products"}}):_vm._e(),(isActive && isExactActive)?_c('img',{attrs:{"src":"/media/dashboard/menu-icons/users-red.png","alt":"All Products"}}):_vm._e()]),_c('span',{staticClass:"menu-text"},[_vm._v("Users")])])])]}}])}),_c('router-link',{attrs:{"to":"/promo-code-promotions"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('div',{staticClass:"menu-icon-img"},[(!isActive && !isExactActive)?_c('img',{attrs:{"src":"/media/dashboard/menu-icons/promo-code.png","alt":"Promo Code and Promotions"}}):_vm._e(),(isActive && isExactActive)?_c('img',{attrs:{"src":"/media/dashboard/menu-icons/promo-code-red.png","alt":"Promo Code and Promotions"}}):_vm._e()]),_c('span',{staticClass:"menu-text"},[_vm._v("Promo Codes & Promotions")])])])]}}])}),_c('router-link',{attrs:{"to":"/payments"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('div',{staticClass:"menu-icon-img"},[(!isActive && !isExactActive)?_c('img',{attrs:{"src":"/media/dashboard/menu-icons/card-payment.png","alt":"Card payments"}}):_vm._e(),(isActive && isExactActive)?_c('img',{attrs:{"src":"/media/dashboard/menu-icons/card-payment-red.png","alt":"Card payments"}}):_vm._e()]),_c('span',{staticClass:"menu-text"},[_vm._v("Payments")])])])]}}])}),_c('router-link',{attrs:{"to":"/notifications"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('div',{staticClass:"menu-icon-img"},[(!isActive && !isExactActive)?_c('img',{attrs:{"src":"/media/dashboard/menu-icons/notifications.png","alt":"Notifications"}}):_vm._e(),(isActive && isExactActive)?_c('img',{attrs:{"src":"/media/dashboard/menu-icons/notifications-red.png","alt":"Notifications"}}):_vm._e()]),_c('span',{staticClass:"menu-text"},[_vm._v("Notifications")])])])]}}])}),_c('router-link',{attrs:{"to":"/level-bonuses"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('div',{staticClass:"menu-icon-img"},[(!isActive && !isExactActive)?_c('img',{attrs:{"src":"/media/dashboard/menu-icons/level-bonus.png","alt":"level and Bonuses"}}):_vm._e(),(isActive && isExactActive)?_c('img',{attrs:{"src":"/media/dashboard/menu-icons/level-bonus-red.png","alt":"level and Bonuses"}}):_vm._e()]),_c('span',{staticClass:"menu-text"},[_vm._v("Levels & Bonuses")])])])]}}])}),_c('router-link',{attrs:{"to":"/settings"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('div',{staticClass:"menu-icon-img"},[(!isActive && !isExactActive)?_c('img',{attrs:{"src":"/media/dashboard/menu-icons/settings-white.png","alt":"Business Lines"}}):_vm._e(),(isActive && isExactActive)?_c('img',{attrs:{"src":"/media/dashboard/menu-icons/settings.png","alt":"Business Lines"}}):_vm._e()]),_c('span',{staticClass:"menu-text"},[_vm._v("Settings")])])])]}}])}),_c('router-link',{attrs:{"to":"/admins"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('div',{staticClass:"menu-icon-img"},[(!isActive && !isExactActive)?_c('img',{attrs:{"src":"/media/dashboard/menu-icons/adminpanel.png","alt":"Business Lines"}}):_vm._e(),(isActive && isExactActive)?_c('img',{attrs:{"src":"/media/dashboard/menu-icons/adminpanel.png","alt":"Business Lines"}}):_vm._e()]),_c('span',{staticClass:"menu-text"},[_vm._v("Admins")])])])]}}])}),_c('router-link',{attrs:{"to":"/help-center/customer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('div',{staticClass:"menu-icon-img"},[(!isActive)?_c('img',{attrs:{"src":"/media/dashboard/menu-icons/help-center.png","alt":"Help Center"}}):_vm._e(),(isActive)?_c('img',{attrs:{"src":"/media/dashboard/menu-icons/help-center-red.png","alt":"Help Center"}}):_vm._e()]),_c('span',{staticClass:"menu-text"},[_vm._v("Help Center "),(_vm.messageNotification)?_c('span',{staticClass:"menu-text--notification"}):_vm._e()])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }